.social-share {
    display: flex;
    flex-direction: column;
    gap: 7px;
    position: fixed;
    left: 23px;
    bottom: 10px;
    padding: 6px;
    width: 52px;
    /* height: 282px; */
    justify-content: center;
    align-items: center;
    background-color: #fff;
    z-index: 999;
    border-radius: 6px;
    list-style: none;
}

.social-share > li > a > img {
    border-radius: 100%;
}

.social-share > li > a {
    text-decoration: none;
}

.shortcuts h6 {
    text-align: center;
    font: normal normal bold 16px/36px Plus Jakarta Sans;
    color: #1C1C1C;
    border-bottom: 1px solid #D1D1D1;
}